/**
 * @generated SignedSource<<786bed06d58540565df3cbe5477768f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkPlanningStatus = "canceled" | "inProgress" | "inWriting" | "locked" | "worksiteSelected" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningList_ItemFragment$data = {
  readonly friendlyId: string | null | undefined;
  readonly snapshot: {
    readonly clientBase: {
      readonly assignedClient: {
        readonly isDirectSales: boolean;
        readonly name: string;
      } | null | undefined;
      readonly assignedClientInfo: {
        readonly name: string;
      };
    };
    readonly projectBase: {
      readonly arrivalDate: {
        readonly rawValue: string;
      } | null | undefined;
      readonly assignedWorksiteInfo: {
        readonly name: string | null | undefined;
      };
    };
    readonly statuses: ReadonlyArray<WorkPlanningStatus>;
  } | null | undefined;
  readonly " $fragmentType": "WorkPlanningList_ItemFragment";
};
export type WorkPlanningList_ItemFragment$key = {
  readonly " $data"?: WorkPlanningList_ItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_ItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_ItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkPlanning",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statuses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientInternalBase",
          "kind": "LinkedField",
          "name": "clientBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "assignedClient",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDirectSales",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientInfoInternal",
              "kind": "LinkedField",
              "name": "assignedClientInfo",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectInternalBase",
          "kind": "LinkedField",
          "name": "projectBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksiteInfoInternal",
              "kind": "LinkedField",
              "name": "assignedWorksiteInfo",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DateTimeInTimeZone",
              "kind": "LinkedField",
              "name": "arrivalDate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rawValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkPlanningJobRevision",
  "abstractKey": null
};
})();

(node as any).hash = "e22ba706ce5d08d0b41652aff7aecde0";

export default node;

import { Box, Chip, ListItemIcon, ListItemText, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat, isValidDate } from '../common/utils/dateTimeUtils';
import { useCallback, useMemo, useRef } from 'react';
import { QuoteListFragment$key } from './__generated__/QuoteListFragment.graphql';
import { castQuoteKind, isQuoteKind, QuoteKind, quoteKinds } from '../__enums__/QuoteKind';
import { QuoteListFiltersFragment$key } from './__generated__/QuoteListFiltersFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateRange } from '@mui/x-date-pickers-pro';
import { QuoteJobRevisionFilterType } from './__generated__/QuoteListFragmentQuery.graphql';
import { isQuoteStatus, QuoteStatus, quoteStatuses } from '../__enums__/QuoteStatus';
import { QuoteStatusChip } from './QuoteStatusChip';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  TextSearchFilter,
} from '../jobs/JobFilters';
import { languageToLocale, resolvedLanguage } from '../i18n';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { ForwardDispatchBranchAutocompleteProps } from '../common/components/DispatchBranchAutocomplete';
import { ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { ForwardRepresentativeAutocompleteProps } from '../common/components/RepresentativeAutocomplete';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { RequireWrite } from '../auth/Authorization';
import { QuoteList_ActionsFragment$key } from './__generated__/QuoteList_ActionsFragment.graphql';
import { QuoteList_RowFragment$key } from './__generated__/QuoteList_RowFragment.graphql';
import { QuoteList_ItemFragment$key } from './__generated__/QuoteList_ItemFragment.graphql';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';

const quoteListFragment = graphql`
  fragment QuoteListFragment on Query
  @refetchable(queryName: "QuoteListFragmentQuery")
  @argumentDefinitions(
    searchTerm: { type: "String" }
    representativeIds: { type: "[ID!]" }
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int" }
    last: { type: "Int" }
    where: { type: "QuoteJobRevisionFilterType" }
  ) {
    searchQuotes(
      searchTerm: $searchTerm
      representativeIds: $representativeIds
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
    ) @required(action: THROW) {
      ...ResponsiveGridFragment
      edges {
        node {
          id
          lifeCycleBranchId
          ...QuoteList_RowFragment
          ...QuoteList_ItemFragment
        }
      }
    }
  }
`;

export type QuoteSearchParamFilters = {
  searchTerm: string;
  capacity: readonly number[];
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  dispatchBranch: readonly { id: DataID }[];
  equipmentKind: readonly { code: number }[];
  kind: readonly QuoteKind[];
  projectManager: readonly { id: DataID }[];
  representative: readonly { id: DataID }[];
  status: readonly QuoteStatus[];
};

export type QuoteResponsiveGridFilters = {
  searchTerm: string;
  capacity: readonly number[];
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  dispatchBranch: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['value']>;
  equipmentKind: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
  kind: readonly QuoteKind[];
  projectManager: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  representative: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  status: readonly QuoteStatus[];
};

export function deserializeURLParams(searchParams: URLSearchParams): QuoteSearchParamFilters {
  const arrivalDateStart = searchParams.get('arrivalDateStart');
  const arrivalDateEnd = searchParams.get('arrivalDateEnd');

  let arrivalDate;
  if (!isValidDate(arrivalDateStart) || !isValidDate(arrivalDateEnd)) {
    arrivalDate = null;
  } else {
    arrivalDate = { start: arrivalDateStart!, end: arrivalDateEnd! };
  }

  return {
    searchTerm: searchParams.get('searchTerm') ?? '',
    capacity: searchParams
      .getAll('capacity')
      .map((element) => parseInt(element))
      .filter((num) => !isNaN(num)),
    arrivalDate,
    dispatchBranch: searchParams.getAll('dispatchBranchId').map((b) => ({ id: b })),
    equipmentKind: searchParams
      .getAll('equipmentKindCode')
      .map((element) => parseInt(element))
      .filter((num) => !isNaN(num))
      .map((e) => ({ code: e })),
    kind: searchParams.getAll('kind').filter(isQuoteKind),
    projectManager: searchParams.getAll('projectManagerId').map((pm) => ({ id: pm })),
    representative: searchParams.getAll('representativeId').map((rep) => ({ id: rep })),
    status: searchParams.getAll('status').filter(isQuoteStatus),
  };
}

export function serializeFilters(filters: QuoteSearchParamFilters): QuoteJobRevisionFilterType {
  const simplify = (op: 'and' | 'or', fs: QuoteJobRevisionFilterType[]): QuoteJobRevisionFilterType => {
    const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
    return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
  };
  const and = (fs: QuoteJobRevisionFilterType[]) => simplify('and', fs);
  const or = (fs: QuoteJobRevisionFilterType[]) => simplify('or', fs);

  return and([
    filters.capacity.length
      ? {
          snapshot: {
            equipmentBase: {
              craneSelector: { favoriteConfiguration: { capacity: { in: filters.capacity } } },
            },
          },
        }
      : {},
    filters.arrivalDate
      ? {
          snapshot: {
            projectBase: {
              arrivalDate: {
                date: {
                  gte: filters.arrivalDate.start,
                  lte: filters.arrivalDate.end,
                },
              },
            },
          },
        }
      : {},
    filters.dispatchBranch.length ? { snapshot: { project: { dispatchBranchId: { in: filters.dispatchBranch.map((d) => d.id) } } } } : {},
    filters.equipmentKind.length
      ? {
          snapshot: {
            equipmentBase: {
              craneSelector: {
                favoriteConfiguration: { equipmentKindCode: { in: filters.equipmentKind.map((e) => e.code) } },
              },
            },
          },
        }
      : {},
    filters.kind.length ? { snapshot: { kind: { in: filters.kind } } } : {},
    filters.projectManager.length ? { snapshot: { client: { projectManagerId: { in: filters.projectManager.map((pm) => pm.id) } } } } : {},
    or(filters.status.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
  ]);
}

export interface QuoteListProps extends ResponsiveGridForwardProps {
  $key: QuoteListFragment$key;
  filters$key: QuoteListFiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: QuoteSearchParamFilters;
  onApplyFilters: (filters: QuoteSearchParamFilters) => void;
}

export function QuoteList({
  $key,
  filters$key,
  write$key,
  filters,
  onApplyFilters: handleApplyFilters,
  onItemClick,
  ...gridProps
}: QuoteListProps) {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();

  const [data, refetch] = useRefetchableFragment(quoteListFragment, $key);
  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.searchTerm, '|'),
          representativeIds: filters.representative.map((r) => r.id),
          where: serializeFilters(filters),
        },
        options,
      ),
    [refetch, filters],
  );

  const edges = data.searchQuotes?.edges;

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
    { id: 'actions', label: '', size: 'minmax(3rem, auto)' },
  ];

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <QuoteList_Row $key={node} write$key={write$key} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <QuoteList_Item fragmentKey={node} />);

  const handleItemClick = useCallback(
    (id: DataID) => {
      return onItemClick?.(getNodeById(id, edges).lifeCycleBranchId);
    },
    [edges, onItemClick],
  );

  const rowSkeletonFactory = useSkeletonFactory(() => <RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ListSkeleton />);

  return (
    data.searchQuotes && (
      <>
        <QuoteFiltersComponent fragmentKey={filters$key} filters={filters} onApplyFilters={handleApplyFilters} />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={data.searchQuotes}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={{
            "&[data-mode='grid']": {
              // Adds a padding in cells to improve readability, specially when elipsing.
              'li.responsive-grid__header  > *': {
                px: '1rem',
              },
              'li:not(.responsive-grid__header)  > *': {
                px: '0.5rem',
              },
            },

            // Controls the gap between the content of list items and the status chip.
            "&[data-mode='list'] > li": {
              gap: '0.5rem',
            },
          }}
          onItemClick={handleItemClick}
          {...gridProps}
        />
      </>
    )
  );
}

export const emptyFilters: QuoteResponsiveGridFilters = {
  searchTerm: '',
  capacity: [],
  arrivalDate: null,
  dispatchBranch: [],
  equipmentKind: [],
  kind: [],
  projectManager: [],
  representative: [],
  status: [],
};

function QuoteFiltersComponent({
  fragmentKey,
  filters,
  onApplyFilters: handleApplyFilters,
}: {
  fragmentKey: QuoteListFiltersFragment$key;
  filters: QuoteSearchParamFilters;
  onApplyFilters: (filters: QuoteSearchParamFilters) => void;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const queriedFilters = useFragment(
    graphql`
      fragment QuoteListFiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
        representativeIds: { type: "[ID!]!" }
      ) {
        selectedDispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label
          }
        }
        selectedProjectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        selectedRepresentatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    fragmentKey,
  );

  return (
    <ResponsiveGridFilters<QuoteResponsiveGridFilters>
      initialFilters={{
        ...filters,
        dispatchBranch: queriedFilters.selectedDispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
        equipmentKind: queriedFilters.equipmentKinds,
        projectManager: queriedFilters.selectedProjectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
        representative: queriedFilters.selectedRepresentatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      }}
      emptyFilters={emptyFilters}
      onApply={handleApplyFilters}
      compact={compact}
      sx={{
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      }}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.searchTerm}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => ({ ...prev, searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.kind}
            label={t('list.column.kind')}
            options={quoteKinds}
            onChange={(kind) => setState((prev) => ({ ...prev, kind }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.capacity}
            onChange={(value) => setState((prev) => ({ ...prev, capacity: value.map((c) => c.capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.equipmentKind}
            onChange={(equipmentKind) => setState((prev) => ({ ...prev, equipmentKind }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.status}
            options={quoteStatuses.filter((s) => s !== 'locked')}
            onChange={(status) => setState((prev) => ({ ...prev, status }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <QuoteStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.dispatchBranch}
            onChange={(dispatchBranch) => setState((prev) => ({ ...prev, dispatchBranch }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.projectManager}
            onChange={(projectManager) => setState((prev) => ({ ...prev, projectManager }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.representative}
            onChange={(representative) => setState((prev) => ({ ...prev, representative }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.arrivalDate}
            onChange={(v: DateRange<DateTime>) => {
              const start = v[0]?.toISODate() ?? null;
              const end = v[1]?.toISODate() ?? null;

              if (!start || !end) {
                setState((prev) => ({ ...prev, arrivalDate: null }));
              } else {
                setState((prev) => ({ ...prev, arrivalDate: { start, end } }));
              }
            }}
          />
        ),
      ]}
    />
  );
}

export function QuoteList_Row({
  $key,
  write$key,
  orderByColumns,
}: {
  $key: QuoteList_RowFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t, i18n } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const $data = useFragment(
    graphql`
      fragment QuoteList_RowFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          kind
          statuses
          clientBase {
            assignedClient {
              name
              category
              number
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
        ...QuoteList_ActionsFragment
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const clientNameInfo = useMemo(
    () => ({ number: snapshot?.clientBase.assignedClient?.number, name: clientName }),
    [clientName, snapshot?.clientBase.assignedClient?.number],
  );
  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={clientName} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell
              value={clientNameInfo}
              formatter={(v) => (
                <>
                  {v.number} —&nbsp;{v.name}
                </>
              )}
            />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && snapshot?.clientBase.assignedClient?.category !== 'NONE' && (
            <Chip variant='outlined' label={snapshot?.clientBase.assignedClient?.category} size='small' />
          )}
        </Box>,

        <EllipsisedTypography key='worksite' variant='body2' component='span'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span'>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <QuoteStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(languageToLocale[resolvedLanguage(i18n)])
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v).setLocale(languageToLocale[resolvedLanguage(i18n)]).toLocaleString({
                month: 'short',
                day: 'numeric',
              })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <QuoteList_Actions key='actions' $key={$data} write$key={write$key} />,
      ])}
    </>
  );
}

function QuoteList_Actions({ $key, write$key }: { $key: QuoteList_ActionsFragment$key | null; write$key: AuthorizationWriteFragment$key }) {
  const $data = useFragment(
    graphql`
      fragment QuoteList_ActionsFragment on QuoteJobRevision {
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castQuoteKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
    </RequireWrite>
  );
}

export function QuoteList_Item({ fragmentKey }: { fragmentKey: QuoteList_ItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const quote = useFragment(
    graphql`
      fragment QuoteList_ItemFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    fragmentKey,
  );
  const { snapshot } = quote;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={quote.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={quote.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>
        <QuoteStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

function RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

function ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}

import { RelayEnvironmentProvider } from 'react-relay';
import { RelayEnvironment } from './RelayEnvironment';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { routes } from './routes';
import { config } from './config';
import FlagProvider, { IConfig, useUnleashClient } from '@unleash/proxy-client-react';
import { Settings } from 'luxon';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from './theme';
import { CssBaseline } from '@mui/material';
import './index.css';
import { initI18n, languageToLocale, resolvedLanguage } from './i18n';
import { workboxIntegration } from './common/hooks/useAppUpdate';
import { EVENTS } from 'unleash-proxy-client';
import { msalInstance } from './msalInstance';
import { MsalProvider } from '@azure/msal-react';
import { LicenseInfo } from '@mui/x-license';
import { defaultTimezone } from './common/utils/dateTimeUtils';
import './idDeserializer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import i18n from 'i18next';

LicenseInfo.setLicenseKey('a59a37a6353be025a36e689d9cf6e18bTz03Nzg1MixFPTE3MzA0ODYwMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
initI18n();

Settings.defaultLocale = languageToLocale[resolvedLanguage(i18n)];
Settings.defaultZone = defaultTimezone;

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [config.API_URL],
      }),
    ],
    release: config.SENTRY_RELEASE,
    environment: config.SENTRY_ENVIRONMENT,
    tracesSampleRate: config.SENTRY_TRACESSAMPLERATE,
    replaysSessionSampleRate: config.SENTRY_REPLAYSSESSIONSAMPLERATE,
    replaysOnErrorSampleRate: config.SENTRY_REPLAYSONERRORSAMPLERATE,
  });
}

const featureFlagConfig: IConfig = {
  url: `${config.FEATURE_FLAG_HOST}/`, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: config.FEATURE_FLAG_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 15,
  appName: 'Not-required-set-by-the-proxy', // Required to implement the IConfig interface...
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

function WaitForFlags({ children }: { children: JSX.Element }) {
  const [flagsReady, setFlagsReady] = useState(false);

  const client = useUnleashClient();
  client.once(EVENTS.INIT, () => setFlagsReady(true));

  return flagsReady ? children : null;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <FlagProvider config={featureFlagConfig}>
        <WaitForFlags>
          <ThemeProvider theme={mainTheme}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='fr-CA'>
              <CssBaseline />
              <RouterProvider router={router} />
            </LocalizationProvider>
          </ThemeProvider>
        </WaitForFlags>
      </FlagProvider>
    </RelayEnvironmentProvider>
  </MsalProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: workboxIntegration.handleUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

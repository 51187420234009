import { AddendumKind } from '../../__enums__/AddendumKind';
import { AttachmentKind } from '../../__enums__/AttachmentKind';
import { CancelReason } from '../../__enums__/CancelReason';

const addendumKind: Record<AddendumKind, string> = {
  archived: 'Archivé',
  clientOrWorksite: 'Client / chantier',
  craneOperator: 'Grutier',
  dispatching: 'Répartition',
  invoicing: 'Facturation',
  quote: 'Soumission',
  salesSupervisor: 'Superviseur des ventes',
  supervisionNote: 'Note de supervision',
  internalNote: 'Note interne',
};

const alert = {
  creditMessage: 'Message du credit',
  billingRequirements: 'Exigences de facturation',
};

const dialog = {
  cancel: {
    title: 'Annuler le projet',
    body: 'Êtes-vous certain de vouloir annuler ce projet\u00a0? Cette opération est irréversible.',
    confirmButton: 'Annuler',
    reason: 'Raison',
    comment: 'Commentaire',
  },
  copy: {
    accessories: 'Accessoires',
    addenda: 'Addenda',
    attachments: 'Pièces jointes',
    body: 'Désirez-vous également copier les informations\u00a0?',
    confirmButton: 'Ok',
    newProject: 'Nouveau projet',
    sameProject: 'Même projet',
    title: 'Copier le document',
  },
};

const salesRate = {
  missingPrice: 'Prix manquant',
  minimumQuantity: 'Quantité minimum de {{quantity}}',
  unexpected: 'Erreur inattendue',
};

export const jobs = {
  addendum: {
    deleteTitle: 'Supprimer l’addenda',
    deleteMessage: 'Voulez-vous vraiment supprimer cet addenda\u00a0?',
  },
  addendumKind,
  alert,
  attachmentKind: {
    assemblySequence: 'Séquence de montage',
    bargeStabilityStudy: 'Étude de stabilité sur barge',
    bargeStowageInspection: 'Inspection d’arrimage sur barge',
    certifications: 'Certifications',
    charters: 'Chartes',
    competenceCards: 'Cartes de compétence',
    engineeringCertificate: 'Attestation d’ingénieur',
    feasibilityStudy: 'Étude de faisabilité',
    groundPressureCalculations: 'ST - Calculs de pression au sol',
    insuranceDeclaration: 'Déclaration aux assurances',
    liftingPlanOneCrane: 'ST - Plan de levage 1 grue',
    liftingPlanReview: 'ST - Révision plan de levage',
    liftingPlanTwoCranes: 'ST - Plan de levage 2 grues',
    listOfTransports: 'Liste des transports',
    location: 'Emplacement',
    others: 'Autres',
    parkingPermit: 'Permis de stationnement',
    photos: 'Photos',
    projectDocuments: 'Documents de projets',
    roadSpecifications: 'ST - Spécifications de la route',
    signage: 'Signalisation',
    technicalDrawings: 'Dessins Techniques',
    transportPermit: 'Permis de transport',
    travelSpecifications: 'ST - Spécifications de déplacement',
    workMethodEventSealedByEngineer: 'ST - Méthode de travail (événement) scellée par ingénieur',
  } satisfies Record<AttachmentKind, string>,
  buttons: {
    consultRequirements: 'Consulter',
    seeJobDocuments: 'Documents',
  },
  cancelReasons: {
    craneSelectionError: 'Erreur de choix de grue',
    deferredWorkLongTerm: 'Travaux reportés (long terme)',
    duplicates: 'Doublons',
    equipmentBreakdownGarage: 'Bris d’équipement (Garage)',
    forgotToCancel: 'Oubli d’annulation',
    forgotToEnterServiceCall: 'Oubli de saisie d’appel de service',
    forgotToSendCraneOperator: 'Oubli d’envoi du grutier',
    lackOfEquipment: 'Manque d’équipement',
    lostByCustomer: 'Perdu par le client',
    lostCompetitor: 'Perdu compétiteur ',
    notTheRightEquipment: 'Pas le bon équipement',
    projectAbandoned: 'Projet abandonné',
    withTheirCrane: 'Avec leur grue',
    dispatch: 'Annulé par répartition',
  } satisfies Record<CancelReason, string>,
  crane: {
    additional: 'Additionnelle {{ index }}',
    additionalShort: 'A{{ index }}',
    main: 'Principale',
    mainShort: 'P',
  },
  details: {
    section: {
      attachments: 'Pièces jointes',
      jobProgression: 'Documents du projet',
    },
  },
  error: {
    duringUpload: 'Une erreur s’est produite pendant le téléversement.',
    duringDownload: 'Une erreur s’est produite pendant le téléchargement.',
    unexpected: 'Une erreur inattendue est survenue. Veuillez réessayer.',
  },
  dialog,
  field: {
    attachments: {
      empty: 'Aucune pièce jointe',
      kind: 'Type de pièce jointe',
      name: 'Nom',
      upload: {
        state: {
          queued: 'En attente de téléversement',
          uploading: 'En téléversement',
        },
      },
    },
    project: {
      schedulePeriod: {
        startTime: 'Début',
        endTime: 'Fin',
      },
    },
  },
  labels: {
    lastUpdate: 'Mise à jour le',
  },
  pages: {
    attachments: {
      title: 'Pièce Jointe',
    },
  },
  questionsBase: {
    hasWorksiteBeenVisited: 'Est-ce qu’une visite de chantier a été faite\u00a0?',
    selectAllSituations: 'Veuillez sélectionner toutes les situations applicables\u00a0:',
    specialSituation: 'Situations Particulières',
    excavationInformation: 'Information sur l’excavation',
    powerlineInformation: 'Information sur les lignes électriques',
    specialProjects: 'Projets spéciaux',
    warning: {
      worksiteNotVisited: 'Attention risque à identifier - Chantier non visité par le représentant',
    },
    field: {
      angle: 'Angle',
      distance: 'Distance',
      isTilledSoil: 'Sol travaillé\u00a0?',
      isBerlinWall: 'Mur berlinois ou excavation étançonnée\u00a0?',
    },
  },
  revisionNames: {
    ServiceCall: 'Appel de service',
    Quote: 'Soumission',
    WorkPlanning: 'Planification des travaux',
  },
  salesRate,
};

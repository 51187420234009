import { Box, Chip, ListItemIcon, ListItemText, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat, isValidDate } from '../common/utils/dateTimeUtils';
import { useCallback } from 'react';
import { WorkPlanningListFragment$key } from './__generated__/WorkPlanningListFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { WorkPlanningStatusChip } from './WorkPlanningStatusChip';
import { isWorkPlanningStatus, WorkPlanningStatus, workPlanningStatuses } from '../__enums__/WorkPlanningStatus';
import { languageToLocale, resolvedLanguage } from '../i18n';
import { useTranslation } from 'react-i18next';
import { WorkPlanningJobRevisionFilterType } from './__generated__/WorkPlanningListFragmentQuery.graphql';
import { ArrivalDateFilter, JobStatusFilter, RepresentativeFilter, TextSearchFilter } from '../jobs/JobFilters';
import { DateRange } from '@mui/x-date-pickers-pro';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { WorkPlanningListFiltersFragment$key } from './__generated__/WorkPlanningListFiltersFragment.graphql';
import { ForwardRepresentativeAutocompleteProps } from '../common/components/RepresentativeAutocomplete';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { WorkPlanningList_ItemFragment$key } from './__generated__/WorkPlanningList_ItemFragment.graphql';
import { WorkPlanningList_RowFragment$key } from './__generated__/WorkPlanningList_RowFragment.graphql';
import { RequireWrite } from '../auth/Authorization';
import { WorkPlanningList_ActionsFragment$key } from './__generated__/WorkPlanningList_ActionsFragment.graphql';
import { WorkPlanningList_Actions_MeFragment$key } from './__generated__/WorkPlanningList_Actions_MeFragment.graphql';
import { WorkPlanningList_Row_MeFragment$key } from './__generated__/WorkPlanningList_Row_MeFragment.graphql';
import { WorkPlanningList_CopyButton } from './WorkPlanningList.CopyButton';

export type WorkPlanningSearchParamsFilters = {
  searchTerm: string;
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  status: readonly WorkPlanningStatus[];
  representative: readonly { id: DataID }[];
};
export type WorkPlanningResponsiveGridFilters = {
  searchTerm: string;
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  status: readonly WorkPlanningStatus[];
  representative: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
};

export function deserializeURLParams(searchParams: URLSearchParams): WorkPlanningSearchParamsFilters {
  const arrivalDateStart = searchParams.get('arrivalDateStart');
  const arrivalDateEnd = searchParams.get('arrivalDateEnd');

  let arrivalDate;
  if (!isValidDate(arrivalDateStart) || !isValidDate(arrivalDateEnd)) {
    arrivalDate = null;
  } else {
    arrivalDate = { start: arrivalDateStart!, end: arrivalDateEnd! };
  }

  return {
    searchTerm: searchParams.get('searchTerm') ?? '',
    arrivalDate,
    status: searchParams.getAll('status').filter(isWorkPlanningStatus),
    representative: searchParams.getAll('representativeId').map((rep) => ({ id: rep })),
  };
}

export function serializeFilters(filters: WorkPlanningSearchParamsFilters): WorkPlanningJobRevisionFilterType {
  const simplify = (op: 'and' | 'or', fs: WorkPlanningJobRevisionFilterType[]): WorkPlanningJobRevisionFilterType => {
    const activeFilters = fs.filter((f) => Object.keys(f).length); //ignore empty filters
    return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
  };
  const and = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('and', fs);
  const or = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('or', fs);

  return and([
    filters.arrivalDate
      ? {
          snapshot: {
            projectBase: {
              arrivalDate: {
                date: {
                  gte: filters.arrivalDate.start,
                  lte: filters.arrivalDate.end,
                },
              },
            },
          },
        }
      : {},
    or(filters.status.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
  ]);
}

export interface WorkPlanningListProps extends ResponsiveGridForwardProps {
  fragmentKey: WorkPlanningListFragment$key;
  filtersFragmentKey: WorkPlanningListFiltersFragment$key;
  filters: WorkPlanningSearchParamsFilters;
  onApplyFilters: (filters: WorkPlanningSearchParamsFilters) => void;
}

export function WorkPlanningList({
  fragmentKey,
  filtersFragmentKey,
  onItemClick,
  filters,
  onApplyFilters: handleApplyFilters,
  ...gridProps
}: WorkPlanningListProps) {
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment WorkPlanningListFragment on Query
      @refetchable(queryName: "WorkPlanningListFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        representativeIds: { type: "[ID!]" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "WorkPlanningJobRevisionFilterType" }
      ) {
        searchWorkPlannings(
          searchTerm: $searchTerm
          representativeIds: $representativeIds
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...WorkPlanningList_RowFragment
              ...WorkPlanningList_ItemFragment
            }
          }
        }
        ...WorkPlanningList_Row_MeFragment
      }
    `,
    fragmentKey,
  );
  const edges = data.searchWorkPlannings?.edges;
  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.searchTerm, '|'),
          representativeIds: filters.representative.map((r) => r.id),
          where: serializeFilters(filters),
        },
        options,
      ),
    [refetch, filters],
  );

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 1fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 1fr)' },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
    { id: 'actions', label: '', size: 'minmax(3rem, auto)' },
  ];

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <WorkPlanningList_Row $key={node} me$key={data} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <WorkPlanningList_Item $key={node} />);

  const handleItemClick = useCallback((id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId), [edges, onItemClick]);

  const rowSkeletonFactory = useSkeletonFactory(() => <RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ListSkeleton />);

  return (
    data.searchWorkPlannings && (
      <>
        <WorkPlanningFiltersComponent fragmentKey={filtersFragmentKey} filters={filters} onApplyFilters={handleApplyFilters} />
        <ResponsiveGrid
          connectionFragmentKey={data.searchWorkPlannings}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={{
            "&[data-mode='grid']": {
              // Adds a padding in cells to improve readability, specially when elipsing.
              'li.responsive-grid__header  > *': {
                px: '1rem',
              },
              'li:not(.responsive-grid__header)  > *': {
                px: '0.5rem',
              },
            },

            // Controls the gap between the content of list items and the status chip.
            "&[data-mode='list'] > li": {
              gap: '0.5rem',
            },
          }}
          onItemClick={handleItemClick}
          {...gridProps}
        />
      </>
    )
  );
}

export const emptyFilters: WorkPlanningResponsiveGridFilters = {
  arrivalDate: null,
  status: [],
  representative: [],
  searchTerm: '',
};

function WorkPlanningFiltersComponent({
  fragmentKey,
  filters,
  onApplyFilters: handleApplyFilters,
}: {
  fragmentKey: WorkPlanningListFiltersFragment$key;
  filters: WorkPlanningSearchParamsFilters;
  onApplyFilters: (filters: WorkPlanningSearchParamsFilters) => void;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const queriedFilters = useFragment(
    graphql`
      fragment WorkPlanningListFiltersFragment on Query @argumentDefinitions(representativeIds: { type: "[ID!]!" }) {
        selectedRepresentatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    fragmentKey,
  );

  return (
    <ResponsiveGridFilters<WorkPlanningResponsiveGridFilters>
      initialFilters={{
        ...filters,
        representative: queriedFilters.selectedRepresentatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      }}
      emptyFilters={emptyFilters}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.searchTerm}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => ({ ...prev, searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.status}
            options={workPlanningStatuses.filter((s) => s !== 'locked')}
            onChange={(status) => setState((prev) => ({ ...prev, status }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <WorkPlanningStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.representative}
            onChange={(representative) => setState((prev) => ({ ...prev, representative }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.arrivalDate}
            onChange={(v: DateRange<DateTime>) => {
              const start = v[0]?.toISODate() ?? null;
              const end = v[1]?.toISODate() ?? null;

              if (!start || !end) {
                setState((prev) => ({ ...prev, arrivalDate: null }));
              } else {
                setState((prev) => ({ ...prev, arrivalDate: { start, end } }));
              }
            }}
          />
        ),
      ]}
      onApply={handleApplyFilters}
      compact={compact}
      sx={{ [theme.breakpoints.down('sm')]: { mx: '1rem' }, [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' } }}
    />
  );
}

export function WorkPlanningList_Row({
  $key,
  me$key,
  orderByColumns,
}: {
  $key: WorkPlanningList_RowFragment$key;
  me$key: WorkPlanningList_Row_MeFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { i18n } = useTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_RowFragment on WorkPlanningJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              category
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
        ...WorkPlanningList_ActionsFragment
      }
    `,
    $key,
  );
  const { snapshot } = $data;

  const me$data = useFragment(
    graphql`
      fragment WorkPlanningList_Row_MeFragment on Query {
        ...WorkPlanningList_Actions_MeFragment
      }
    `,
    me$key,
  );

  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const statuses = snapshot?.statuses.every(isWorkPlanningStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={$data.snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell value={clientName} />
          </EllipsisedTypography>
          {$data.snapshot?.clientBase.assignedClient && $data.snapshot?.clientBase.assignedClient?.category !== 'NONE' && (
            <Chip variant='outlined' label={$data.snapshot?.clientBase.assignedClient?.category} size='small' />
          )}
        </Box>,

        <EllipsisedTypography key='worksite' variant='body2' component='span'>
          <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <Box key='status' display='flex' alignItems='center'>
          <WorkPlanningStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO($data.snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(languageToLocale[resolvedLanguage(i18n)])
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v).setLocale(languageToLocale[resolvedLanguage(i18n)]).toLocaleString({
                month: 'short',
                day: 'numeric',
              })
            }
            value={$data.snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,
        <WorkPlanningList_Actions key='actions' $key={$data} me$key={me$data} />,
      ])}
    </>
  );
}

function WorkPlanningList_Actions({
  $key,
  me$key,
}: {
  $key: WorkPlanningList_ActionsFragment$key | null | undefined;
  me$key: WorkPlanningList_Actions_MeFragment$key;
}) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_ActionsFragment on WorkPlanningJobRevision {
        ...WorkPlanningList_CopyButtonFragment
      }
    `,
    $key,
  );

  const me$data = useFragment(
    graphql`
      fragment WorkPlanningList_Actions_MeFragment on Query {
        ...AuthorizationWriteFragment
      }
    `,
    me$key,
  );

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={me$data} fallback={<div />}>
      <WorkPlanningList_CopyButton $key={$data} />
    </RequireWrite>
  );
}

function WorkPlanningList_Item({ $key }: { $key: WorkPlanningList_ItemFragment$key }) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_ItemFragment on WorkPlanningJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const statuses = snapshot?.statuses.every(isWorkPlanningStatus) ? (snapshot?.statuses ?? []) : [];
  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={$data.snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={$data.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={$data.snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='body2' component='p' color='text.secondary'>
          <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
      </ListItemText>
      <ListItemIcon>
        <WorkPlanningStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

function RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

function ListSkeleton() {
  return (
    <ListItemText>
      <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
      <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
      <Skeleton variant='rounded' width='10rem' height='1.25rem' />
    </ListItemText>
  );
}

import { createSharedStateContext, createSharedStateKey, useSharedState } from './common/utils/sharedState';
import { SetStateAction, useCallback, useMemo } from 'react';
import { useSticky } from './common/hooks/useSticky';
import { _throw } from './common/utils/_throw';
import { createOperationDescriptor, getRequest, GraphQLTaggedNode, Observable } from 'relay-runtime';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { useRetainQuery } from './common/hooks/useRetainQuery';
import * as Sentry from '@sentry/react';

export const appSharedStateContext = createSharedStateContext();

type Operations = { [key: string]: { operationsInFlight: number } };
export const operationsKey = createSharedStateKey<Operations>(() => ({}));
const operationNotificationDelay = 250;

export type OperationQuery = { response: Record<string, unknown>; variables: Record<string, unknown> };

export interface RelayOperationExecutorOptions {
  logErrorToSentry?: (error: Error) => boolean;
}

export interface RelayOperationExecutor {
  <Query extends OperationQuery>(
    query: GraphQLTaggedNode,
    variables: Query['variables'],
    operations: ((value: Query['response']) => void)[],
    options?: RelayOperationExecutorOptions,
  ): Observable<Query['response']>;
}

export type UseOperationResult = {
  startOperation: (count?: number) => void;
  endOperation: (count?: number) => void;
  hasOperationInFlight: boolean;
  hasOperationInFlightForKey: boolean;
  shouldNotify: boolean;
  shouldNotifyForKey: boolean;
};

/**
 * Manage and track asynchronous operations.
 *
 * @param {string} key - A key representing the operation. Used to control operations by key when required.
 *
 * @returns {Object} - An object containing the following properties and functions:
 *   - {Function} startOperation - Signal the start of an operation.
 *   - {Function} endOperation - Signal the end of an operation.
 *   - {boolean} hasOperationInFlight - Indicates if there are any operations in flight.
 *   - {boolean} hasOperationInFlightForKey - Indicates if there are any operations in flight for the specified key.
 *   - {boolean} shouldNotify - A boolean indicating if user should be notified for in flight operation. Value is debounced from {@link hasOperationInFlight}. If operation is very fast {@link operationNotificationDelay}, there is no need to notify user.
 *   - {boolean} shouldNotifyForKey - Same as {@link shouldNotify} for a specific key.
 */
export function useOperations(key: string): UseOperationResult {
  const [state, setState] = useSharedState(appSharedStateContext, operationsKey);
  const hasOperationInFlight = useMemo(() => Object.values(state).reduce((acc, cur) => acc + cur.operationsInFlight, 0) > 0, [state]);
  const hasOperationInFlightForKey = state[key]?.operationsInFlight > 0;
  const shouldNotify = useSticky(hasOperationInFlight, operationNotificationDelay, (v) => !v);
  const shouldNotifyForKey = useSticky(hasOperationInFlightForKey, operationNotificationDelay, (v) => !v);

  const startOperation = useCallback(
    (count: number = 1) =>
      setState((prev) => {
        const ops = prev[key] ?? { operationsInFlight: 0 };
        return { ...prev, [key]: { ...ops, operationsInFlight: ops.operationsInFlight + count } };
      }),
    [key, setState],
  );
  const endOperation = useCallback(
    (count: number = 1) =>
      setState((prev) => {
        const { [key]: ops, ...rest } = prev;
        if (!ops) return prev;
        const newOps = { ...ops, operationsInFlight: ops.operationsInFlight - count };
        if (newOps.operationsInFlight <= 0) return rest;

        return { ...rest, [key]: newOps };
      }),
    [key, setState],
  );

  return useMemo(
    () => ({
      startOperation,
      endOperation,
      hasOperationInFlight,
      hasOperationInFlightForKey,
      shouldNotify,
      shouldNotifyForKey,
    }),
    [endOperation, hasOperationInFlight, hasOperationInFlightForKey, shouldNotify, shouldNotifyForKey, startOperation],
  );
}

type OperationsError = { [key: string]: { error: Error } };
export const operationsErrorKey = createSharedStateKey<OperationsError>(() => ({}));

/**
 * Manage and track errors for asynchronous operations. Use this hook for errors not managed by the {@link useErrorBanner} that we want to track globally.
 *
 * @param {string} key - A key representing the operation.
 *
 * @returns {Object} - An object containing the following properties and functions:
 *   - {Error | undefined} error - The error associated with the specified key, or undefined if no error is present.
 *   - {Function} setError - Set the error for the specified key.
 *   - {Function} resetError - Reset the error for the specified key.
 */
export function useOperationsError(key: string): {
  error: Error | undefined;
  setError: (error: SetStateAction<Error | undefined>) => void;
  resetError: () => void;
} {
  const [state, setState] = useSharedState(appSharedStateContext, operationsErrorKey);

  const error = state[key]?.error;
  const setError = useCallback(
    (err: SetStateAction<Error | undefined>) =>
      setState((prev) => ({
        ...prev,
        [key]: {
          error:
            (typeof err === 'function' ? err(prev[key]?.error) : err) ??
            _throw(new Error('Invalid Operation, error needs to be set to a valid value')),
        },
      })),
    [key, setState],
  );
  const resetError = useCallback(() => setState(({ [key]: _, ...rest }) => rest), [key, setState]);

  return useMemo(() => ({ error, setError, resetError }), [error, resetError, setError]);
}

/**
 * Manage and track asynchronous operations specifically for relay.
 *
 * @param key - A key representing the operation. Used to control operations by key when required.
 *
 * @returns Factory function that takes a list of operations for a given query and produce an object to orchestrate those operation
 */
export function useOperationsRelay(key: string): RelayOperationExecutor {
  const env = useRelayEnvironment();
  const [, retainQuery] = useRetainQuery();

  const { startOperation, endOperation } = useOperations(key);
  const { setError, resetError } = useOperationsError(key);

  return useCallback<RelayOperationExecutor>(
    <Query extends OperationQuery>(
      query: GraphQLTaggedNode,
      variables: Query['variables'],
      operations: ((value: Query['response']) => void)[],
      { logErrorToSentry = () => true }: RelayOperationExecutorOptions = {},
    ) =>
      fetchQuery<Query>(env, query, variables).do({
        start: () => {
          resetError();
          startOperation(operations.length);
          // Need to manually retain data in the store since fetchQuery does not retain the data
          // https://relay.dev/docs/guided-tour/accessing-data-without-react/retaining-queries/
          const queryRequest = getRequest(query);
          const queryDescriptor = createOperationDescriptor(queryRequest, variables);
          retainQuery(env.retain(queryDescriptor));
        },
        next: (value) => {
          for (const operation of operations) {
            operation(value);
          }
        },
        error: (error: Error) => {
          if (logErrorToSentry(error)) {
            Sentry.captureException(error);
          }
          setError(error);
          endOperation(operations.length);
        },
        unsubscribe: () => {
          resetError();
          endOperation(operations.length);
        },
      }),
    [endOperation, env, resetError, retainQuery, setError, startOperation],
  );
}

import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { AccessoryTypeLabelResolver$key } from './__generated__/AccessoryTypeLabelResolver.graphql';

/**
 * @RelayResolver AccessoryTypeLookup.label: Localized
 * @rootFragment AccessoryTypeLabelResolver
 *
 * An object with a label for each language for AccessoryType which includes code and shortDescription.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(accessoryTypeLabel$key: AccessoryTypeLabelResolver$key): Record<string, string> {
  const accessoryTypeLabel$data = readFragment(
    graphql`
      fragment AccessoryTypeLabelResolver on AccessoryTypeLookup {
        code
        shortDescription
        units
      }
    `,
    accessoryTypeLabel$key,
  );

  return Object.fromEntries(
    Object.entries(accessoryTypeLabel$data.shortDescription).map(([k, v]) => [
      k,
      `${accessoryTypeLabel$data.code} - ${v} ${accessoryTypeLabel$data.units ? `(${accessoryTypeLabel$data.units[k]})` : ''}`,
    ]),
  );
}

/**
 * @generated SignedSource<<b71d47c42194d5088e5d64045c699c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_QuoteStatusNotificationFragment$data = {
  readonly arrivalDate: {
    readonly date: string;
  };
  readonly clientName: string;
  readonly craneCapacity: number;
  readonly equipmentKindAbbreviation: string;
  readonly friendlyId: string;
  readonly isRead: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_PermalinkFragment">;
  readonly " $fragmentType": "NotificationRow_QuoteStatusNotificationFragment";
};
export type NotificationRow_QuoteStatusNotificationFragment$key = {
  readonly " $data"?: NotificationRow_QuoteStatusNotificationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_QuoteStatusNotificationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_QuoteStatusNotificationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DateTimeInTimeZone",
      "kind": "LinkedField",
      "name": "arrivalDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentKindAbbreviation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "craneCapacity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_PermalinkFragment"
    }
  ],
  "type": "QuoteStatusChangeNotification",
  "abstractKey": "__isQuoteStatusChangeNotification"
};

(node as any).hash = "b5d167c3d0907d48275a3374dea471be";

export default node;

import { Box, InputAdornment, SelectChangeEvent, Typography } from '@mui/material';
import Qty from 'js-quantities';
import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { isDecimalNumber } from '../utils/numberUtils';
import { NumberInput } from './NumberInput';
import { useEffectEvent } from '../utils/effectUtils';
import { defaultLogger, Logger } from '../utils/logging';

const logger = new Logger(defaultLogger, 'LengthInput', () => new Date().toISOString());

export const lengthUnits = ['ft', 'in', 'ftin', 'mm', 'cm', 'm', 'km'] as const;
export type LengthUnit = (typeof lengthUnits)[number];

function isLengthUnit(val: unknown): val is LengthUnit {
  return lengthUnits.includes(val as LengthUnit);
}

interface Props {
  value: string;
  enabledUnits: readonly LengthUnit[];
  label?: string;
  onChange: (val: string) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

const makeCurrentValue = (scalar: number | undefined, footInchScalar: number | undefined, unit: LengthUnit) => {
  if (scalar == null && footInchScalar == null) {
    return '';
  }
  return unit === 'ftin' ? `${footInchScalar || 0} ft ${scalar || 0} in` : `${scalar} ${unit}`;
};

export const parseFtIn = (value: string): { feet: number; inches: number } | null => {
  const parts = value.trim().split(' ');
  if (parts.length >= 4 && parts[1] === 'ft' && parts[3] === 'in') {
    const feet = parseFloat(parts[0]);
    const inches = parseFloat(parts[2]);
    if (!isNaN(feet) && !isNaN(inches)) {
      return { feet, inches };
    }
  }
  return null;
};

export const LengthInput = forwardRef(function LengthInput(
  { value, enabledUnits, label, required, disabled, onChange: handleChange, error, className }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  if (enabledUnits.length === 0) {
    throw new Error('Empty enabledUnits. At least one unit must be enabled.');
  }

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [footInchScalar, setFootInchScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<LengthUnit>(enabledUnits[0]);
  const { t } = useTranslation('common');

  const setState = useCallback((ftinSclr: typeof footInchScalar, sclr: typeof scalar, u: typeof unit) => {
    setFootInchScalar(ftinSclr);
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent((val: string, enbldUnts: readonly LengthUnit[]) => {
    if (!val) {
      // Since makeCurrentValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      const defaultUnit = enbldUnts.includes(unit) ? unit : enbldUnts[0];
      return setState(undefined, undefined, defaultUnit);
    }

    const ftIn = parseFtIn(val);
    if (ftIn) {
      return setState(ftIn.feet, ftIn.inches, 'ftin');
    }

    const quantity = Qty.parse(val);
    if (!quantity) {
      logger.error("Couldn't parse value", val);
      return setState(undefined, undefined, enbldUnts[0]);
    }

    const qtyUnit = quantity.units();
    if (!isLengthUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Length unit', qtyUnit);
      return setState(undefined, undefined, enbldUnts[0]);
    }

    if (!enbldUnts.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enbldUnts);
      return setState(undefined, undefined, enbldUnts[0]);
    }

    return setState(undefined, quantity.scalar, qtyUnit);
  });
  useEffect(() => {
    syncState(value, enabledUnits);
  }, [syncState, value, enabledUnits]);

  const handleDropDownChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isLengthUnit(event.target.value) ? event.target.value : enabledUnits[0];
      setUnit(newUnit);
      handleChange(makeCurrentValue(scalar, footInchScalar, newUnit));
    },
    [enabledUnits, footInchScalar, handleChange, scalar],
  );

  const handleFootInchScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setFootInchScalar(newValue);
        handleChange(makeCurrentValue(scalar, newValue, unit));
      } else {
        setFootInchScalar(undefined);
        handleChange(makeCurrentValue(scalar, undefined, unit));
      }
    },
    [handleChange, scalar, unit],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(makeCurrentValue(newValue, footInchScalar, unit));
      } else {
        setScalar(undefined);
        handleChange(makeCurrentValue(undefined, footInchScalar, unit));
      }
    },
    [footInchScalar, handleChange, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar) {
      const { intValue } = isDecimalNumber(scalar);
      setScalar(intValue);
      handleChange(makeCurrentValue(intValue, footInchScalar, unit));

      if (scalar < 0) {
        setScalar(0);
        handleChange(makeCurrentValue(0, footInchScalar, unit));
      }
    }
  }, [footInchScalar, handleChange, scalar, unit]);

  const handleFootBlur = useCallback(() => {
    if (footInchScalar) {
      const { intValue } = isDecimalNumber(footInchScalar);
      setFootInchScalar(intValue);
      handleChange(makeCurrentValue(scalar, intValue, unit));

      if (footInchScalar < 0) {
        setFootInchScalar(0);
        handleChange(makeCurrentValue(scalar, 0, unit));
      }
    }
  }, [footInchScalar, handleChange, scalar, unit]);

  return (
    <Box display='flex' gap={1}>
      {unit === 'ftin' && (
        <DualUnitAdditionalField
          required={required}
          fieldLabel={label}
          error={error}
          onBlur={handleFootBlur}
          footInchScalar={footInchScalar}
          onChange={handleFootInchScalarChange}
          disabled={disabled}
        />
      )}
      <UnitInput<LengthUnit>
        ref={ref}
        supportedUnits={enabledUnits}
        onScalarChange={handleScalarChange}
        onUnitChange={handleDropDownChange}
        fieldLabel={unit !== 'ftin' ? label : ''}
        onBlur={handleBlur}
        scalar={scalar}
        error={error}
        disabled={disabled}
        required={required}
        unit={unit}
        renderMenuValue={(v: LengthUnit) => t('unit.length.short.' + v)}
        renderAdornmentValue={(v: LengthUnit) => (v === 'ftin' ? t('unit.length.short.in') : t('unit.length.short.' + v))}
        className={className}
      />
    </Box>
  );
});

interface DualUnitProps {
  fieldLabel?: string;
  footInchScalar: number | undefined;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (value: number | null, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export const DualUnitAdditionalField = forwardRef(function DualUnitAdditionalField(
  { fieldLabel, footInchScalar, onChange: handleChange, required, onBlur: handleBlur, disabled, error, className }: DualUnitProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const { t } = useTranslation('common');

  return (
    <NumberInput
      ref={ref}
      data-testid='unitTextBox'
      label={fieldLabel}
      value={footInchScalar ?? null}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      error={error}
      required={required}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Typography>{t('unit.length.short.ft')}</Typography>
          </InputAdornment>
        ),
      }}
      className={className}
    />
  );
});
